import _fs from "fs";
import _polyfills from "./polyfills.js";
import _legacyStreams from "./legacy-streams.js";
import _clone from "./clone.js";
import _util from "util";
import _assert from "assert";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
var fs = _fs;
var polyfills = _polyfills;
var legacy = _legacyStreams;
var clone = _clone;
var queue = [];
var util = _util;

function noop() {}

var debug = noop;
if (util.debuglog) debug = util.debuglog("gfs4");else if (/\bgfs4\b/i.test(process.env.NODE_DEBUG || "")) debug = function () {
  var m = util.format.apply(util, arguments);
  m = "GFS4: " + m.split(/\n/).join("\nGFS4: ");
  console.error(m);
};

if (/\bgfs4\b/i.test(process.env.NODE_DEBUG || "")) {
  process.on("exit", function () {
    debug(queue);

    _assert.equal(queue.length, 0);
  });
}

exports = patch(clone(fs));

if (process.env.TEST_GRACEFUL_FS_GLOBAL_PATCH && !fs.__patched) {
  exports = patch(fs);
  fs.__patched = true;
} // Always patch fs.close/closeSync, because we want to
// retry() whenever a close happens *anywhere* in the program.
// This is essential when multiple graceful-fs instances are
// in play at the same time.


exports.close = function (fs$close) {
  return function (fd, cb) {
    return fs$close.call(fs, fd, function (err) {
      if (!err) retry();
      if (typeof cb === "function") cb.apply(this || _global, arguments);
    });
  };
}(fs.close);

exports.closeSync = function (fs$closeSync) {
  return function (fd) {
    // Note that graceful-fs also retries when fs.closeSync() fails.
    // Looks like a bug to me, although it's probably a harmless one.
    var rval = fs$closeSync.apply(fs, arguments);
    retry();
    return rval;
  };
}(fs.closeSync); // Only patch fs once, otherwise we'll run into a memory leak if
// graceful-fs is loaded multiple times, such as in test environments that
// reset the loaded modules between tests.
// We look for the string `graceful-fs` from the comment above. This
// way we are not adding any extra properties and it will detect if older
// versions of graceful-fs are installed.


if (!/\bgraceful-fs\b/.test(fs.closeSync.toString())) {
  fs.closeSync = exports.closeSync;
  fs.close = exports.close;
}

function patch(fs) {
  // Everything that references the open() function needs to be in here
  polyfills(fs);
  fs.gracefulify = patch;
  fs.FileReadStream = ReadStream; // Legacy name.

  fs.FileWriteStream = WriteStream; // Legacy name.

  fs.createReadStream = createReadStream;
  fs.createWriteStream = createWriteStream;
  var fs$readFile = fs.readFile;
  fs.readFile = readFile;

  function readFile(path, options, cb) {
    if (typeof options === "function") cb = options, options = null;
    return go$readFile(path, options, cb);

    function go$readFile(path, options, cb) {
      return fs$readFile(path, options, function (err) {
        if (err && (err.code === "EMFILE" || err.code === "ENFILE")) enqueue([go$readFile, [path, options, cb]]);else {
          if (typeof cb === "function") cb.apply(this || _global, arguments);
          retry();
        }
      });
    }
  }

  var fs$writeFile = fs.writeFile;
  fs.writeFile = writeFile;

  function writeFile(path, data, options, cb) {
    if (typeof options === "function") cb = options, options = null;
    return go$writeFile(path, data, options, cb);

    function go$writeFile(path, data, options, cb) {
      return fs$writeFile(path, data, options, function (err) {
        if (err && (err.code === "EMFILE" || err.code === "ENFILE")) enqueue([go$writeFile, [path, data, options, cb]]);else {
          if (typeof cb === "function") cb.apply(this || _global, arguments);
          retry();
        }
      });
    }
  }

  var fs$appendFile = fs.appendFile;
  if (fs$appendFile) fs.appendFile = appendFile;

  function appendFile(path, data, options, cb) {
    if (typeof options === "function") cb = options, options = null;
    return go$appendFile(path, data, options, cb);

    function go$appendFile(path, data, options, cb) {
      return fs$appendFile(path, data, options, function (err) {
        if (err && (err.code === "EMFILE" || err.code === "ENFILE")) enqueue([go$appendFile, [path, data, options, cb]]);else {
          if (typeof cb === "function") cb.apply(this || _global, arguments);
          retry();
        }
      });
    }
  }

  var fs$readdir = fs.readdir;
  fs.readdir = readdir;

  function readdir(path, options, cb) {
    var args = [path];

    if (typeof options !== "function") {
      args.push(options);
    } else {
      cb = options;
    }

    args.push(go$readdir$cb);
    return go$readdir(args);

    function go$readdir$cb(err, files) {
      if (files && files.sort) files.sort();
      if (err && (err.code === "EMFILE" || err.code === "ENFILE")) enqueue([go$readdir, [args]]);else {
        if (typeof cb === "function") cb.apply(this || _global, arguments);
        retry();
      }
    }
  }

  function go$readdir(args) {
    return fs$readdir.apply(fs, args);
  }

  if (process.version.substr(0, 4) === "v0.8") {
    var legStreams = legacy(fs);
    ReadStream = legStreams.ReadStream;
    WriteStream = legStreams.WriteStream;
  }

  var fs$ReadStream = fs.ReadStream;

  if (fs$ReadStream) {
    ReadStream.prototype = Object.create(fs$ReadStream.prototype);
    ReadStream.prototype.open = ReadStream$open;
  }

  var fs$WriteStream = fs.WriteStream;

  if (fs$WriteStream) {
    WriteStream.prototype = Object.create(fs$WriteStream.prototype);
    WriteStream.prototype.open = WriteStream$open;
  }

  fs.ReadStream = ReadStream;
  fs.WriteStream = WriteStream;

  function ReadStream(path, options) {
    if ((this || _global) instanceof ReadStream) return fs$ReadStream.apply(this || _global, arguments), this || _global;else return ReadStream.apply(Object.create(ReadStream.prototype), arguments);
  }

  function ReadStream$open() {
    var that = this || _global;
    open(that.path, that.flags, that.mode, function (err, fd) {
      if (err) {
        if (that.autoClose) that.destroy();
        that.emit("error", err);
      } else {
        that.fd = fd;
        that.emit("open", fd);
        that.read();
      }
    });
  }

  function WriteStream(path, options) {
    if ((this || _global) instanceof WriteStream) return fs$WriteStream.apply(this || _global, arguments), this || _global;else return WriteStream.apply(Object.create(WriteStream.prototype), arguments);
  }

  function WriteStream$open() {
    var that = this || _global;
    open(that.path, that.flags, that.mode, function (err, fd) {
      if (err) {
        that.destroy();
        that.emit("error", err);
      } else {
        that.fd = fd;
        that.emit("open", fd);
      }
    });
  }

  function createReadStream(path, options) {
    return new ReadStream(path, options);
  }

  function createWriteStream(path, options) {
    return new WriteStream(path, options);
  }

  var fs$open = fs.open;
  fs.open = open;

  function open(path, flags, mode, cb) {
    if (typeof mode === "function") cb = mode, mode = null;
    return go$open(path, flags, mode, cb);

    function go$open(path, flags, mode, cb) {
      return fs$open(path, flags, mode, function (err, fd) {
        if (err && (err.code === "EMFILE" || err.code === "ENFILE")) enqueue([go$open, [path, flags, mode, cb]]);else {
          if (typeof cb === "function") cb.apply(this || _global, arguments);
          retry();
        }
      });
    }
  }

  return fs;
}

function enqueue(elem) {
  debug("ENQUEUE", elem[0].name, elem[1]);
  queue.push(elem);
}

function retry() {
  var elem = queue.shift();

  if (elem) {
    debug("RETRY", elem[0].name, elem[1]);
    elem[0].apply(null, elem[1]);
  }
}

export default exports;
export const close = exports.close,
      closeSync = exports.closeSync;